import React, { useContext } from "react"
import { Link } from "gatsby"
import { navigate } from "gatsby"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"
import "./styles.scss"

const Contact = ({ location }) => {
  const langSliced = location.pathname.slice(1, 3)
  const dispatch = useContext(GlobalDispatchContext)
  const path = location.pathname
  const handleTransition = slug => {
    if (path !== `/${slug}`) {
      dispatch({ type: "TOGGLE_TRANSITION" })
      setTimeout(() => {
        navigate(`/${slug}`)
      }, 1000)
    } else {
      return
    }
  }
  return (
    <>
      <section className="success">
        <div className="mobile-header" />
        <h1 className="success-title">
          {langSliced === "en" || langSliced === "fr" ? "Contact" : "Контакт"}
        </h1>
        <div className="success-container">
          <div className="success-container-infos">
            <div>
              <h2>Email</h2>
              <a
                href="mailto:dashadart75@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                dashadart75@gmail.com
              </a>
            </div>
            <div>
              <h2>Tel</h2>
              <a href="tel:+33665985851" target="_blank" rel="noreferrer">
                +33 665 985 851
              </a>
            </div>
            <div>
              <h2>Instagram</h2>
              <a
                href="https://www.instagram.com/dashad.art"
                target="_blank"
                rel="noreferrer"
              >
                @DashaD.Art
              </a>
            </div>
          </div>
          <div className="success-container-form">
            <span role="img">👍</span>
            <p>
              {langSliced === "ru"
                ? "Ваше сообщение было успешно отправлено"
                : langSliced === "fr"
                ? "Votre message a été envoyé avec succès !"
                : "Your message was sent successfully!"}
            </p>
            <Link
              to={`/${langSliced}`}
              className="success-container-form-button"
              onClick={e => {
                e.preventDefault()
                handleTransition(langSliced)
              }}
            >
              {langSliced === "ru"
                ? "Вернуться на домашнюю страницу"
                : langSliced === "fr"
                ? "Retourner à l'accueil"
                : "Return home"}
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Contact
